// FUNCTION IMPORTS
import React from "react";

// STYLE IMPORTS
import s from "./Nav.module.scss";

// COMPONENT IMPORTS
import { Container } from "../Container/Container";

export const Nav = () => {
  return (
    <nav className={s.BarStyle}>
      <Container>
        <div className={s.BarLayout}>
          <p className={s.Title}>
            <a href="/" className={s.UnsetLink}>
              Robyn Schlotfeldt <span className={s.Colour}>|</span> UX Portfolio
            </a>
          </p>
          <ul className={s.ListStyle}>
            <li>
              <a href="/" className={s.NavLink}>
                Work
              </a>
            </li>
            <li>
              <a href="/about-me" className={s.NavLink}>
                Play
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </nav>
  );
};

// @TODO: SORT OUT LINKS
