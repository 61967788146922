import React, { ReactNode } from "react";
import s from "./ImageGallery.module.scss";

interface Props {
  children: ReactNode;
}

export const ImageGallery = ({ children }: Props) => {
  return <div className={s.ImageGallery}>{children}</div>;
};
