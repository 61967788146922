// GENERAL IMPORTS
import s from "./ProjectHeader.module.scss";
import cn from "classnames";

// COMPONENT IMPORTS
import { Container } from "../../components/Container/Container";

interface PropsT {
  image: string;
  title: string;
  intro: string;
}

export const ProjectHeader = ({ intro, title, image }: PropsT) => {
  return (
    <div className={s.SectionWrapper}>
      <div className={s.DesktopImagesLayoutWrapper}>
        <div
          className={cn(s.FeaturedImage)}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      </div>
      <div className={s.ContainerWide}>
        <div className={s.ContentContainer}>
          <div className={s.CopyContainer}>
            <h1 className={s.Title}>{title}</h1>
            <p>{intro}</p>
          </div>
        </div>
      </div>
      <div className={s.MobileImagesLayoutWrapper}>
        <Container>
          <div className={s.MobileImagesLayout}>
            <img src={image} alt={""} className={cn(s.MobileImage)} />
          </div>
        </Container>
      </div>
    </div>
  );
};
