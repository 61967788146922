// GENERAL IMPORTS:
import React from "react";
import s from "./ClientNameTag.module.scss";

interface PropsT {
  src: string;
  name: string;
  alt: string;
}

export const ClientNameTag = ({ src, name, alt }: PropsT) => {
  return (
    <div className={s.Client}>
      {/* <h3 className={s.Name}>{name}</h3> */}
      <img src={src} alt={alt} className={s.Logo} />
    </div>
  );
};
