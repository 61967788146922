// GENERAL IMPORTS
import s from "../../App.module.scss";

// COMPONENT IMPORTS
import { Section } from "../../components/Section/Section";
import { Container } from "../../components/Container/Container";
import { Approach } from "../../sections/Approach/Approach";
import { ApproachStep } from "../../sections/ApproachStep/ApproachStep";
import { ProjectHeader } from "../../components/ProjectHeader/ProjectHeader";
import { Result } from "../../sections/Result/Result";
import { TestimonialSmall } from "../../sections/TestimonialSmall/TestimonialSmall";

// IMAGE IMPORTS
import LotImage from "../../assets/images/team/LOT.png";
import Interviews from "../../assets/images/projects/lot/interviews2.png";
import Stories from "../../assets/images/projects/lot/stories.png";
import Data from "../../assets/images/projects/lot/data.png";
import Testing from "../../assets/images/projects/lot/testing.png";
import Nav from "../../assets/images/projects/Nav2.png";
import Finals from "../../assets/images/projects/lot/finals.png";
import Soph from "../../assets/images/team/Soph.png";
import { useEffect } from "react";

export const LibraryOfThings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ProjectHeader
        image={LotImage}
        title={
          "Design a dashboard that helps the team access and understand key business metrics at a glance"
        }
        intro={"The Library of Things, 2020"}
      ></ProjectHeader>
      <div className={s.Callout}>
        <Section>
          <Container>
            <p className={s.CalloutText}>
              "Robyn was a pleasure to work with. Her methodical approach made
              sure a complex project was delivered quickly, and her dashboard
              designs have brought transformative value to the Library of
              Things."
            </p>
            <p>
              - Sophia Wyatt, Director and Service Designer at Library of Things
            </p>
          </Container>
        </Section>
      </div>
      <Section className={s.Background}>
        <Container>
          <h2 className={s.Subtitle}>Project Background</h2>
          <p className={s.FirstP}>
            The Library of Things was rapidly expanding, but its process to
            access business metrics was cumbersome and limited strategic
            decisions. It relied on a data analyst to pull a manual report each
            time.
          </p>
          <p>
            Without improvement, the business could lose funding opportunities.
          </p>
          <p>
            Leaders needed to be able to see the health of the business
            real-time. Staff at different sites needed to be able to see their
            performance against targets. Potential investors had questions about
            real-time performance and current investors needed to hold the
            business to account.
          </p>
          <div className={s.Challenge}>
            <h2 className={s.Subtitle}>The Challenge: </h2>
            <ul>
              <li>
                Understand what metrics staff and investors need to access
              </li>
              <li>
                Create and test a dashboard prototype relevant to the users'
                role and open to all staff
              </li>
            </ul>
          </div>
        </Container>
      </Section>
      <Container>
        <Section>
          <Approach>
            <ApproachStep
              title={"Learned what metrics staff need to see"}
              activity={"Stakeholder Interviews"}
              step={1}
              description={
                "I met with members of staff across the business to learn what metrics they need to access as a part of their day-to-day roles. We identified 207 different metrics to include in the dashboard and learned that some metrics would need shared views whilst others were only relevant to one person. "
              }
              imgSrc={Interviews}
              imgAlt={
                "A collage of screenshots from stakeholder workshops, showing stakeholders looking happy and engaged in the conversation."
              }
            ></ApproachStep>
            <ApproachStep
              title={"Prioritized metrics"}
              activity={"Prioritization Workshop"}
              step={2}
              description={
                "In a workshop, I collaborated with the team to understand how often each person needed access to their metrics: daily, weekly, monthly, or nice-to-have. I was able to understand which metrics were most important to each role which informed the prioritization of metrics in the interface. "
              }
              imgSrc={Stories}
              imgAlt={
                "A screenshot of a spreadsheet showing some prioritized metrics for the dashboard"
              }
            ></ApproachStep>
            <ApproachStep
              title={"Visualized metrics"}
              activity={"Co-Creation with Data Analyst"}
              step={3}
              description={
                "I worked with the data analyst to understand the best types of graphs and charts to communicate each metric and we experimented with different ways of visualizing progress towards targets. We needed to present the metrics in ways that were easy for everybody to understand at a glance. "
              }
              imgSrc={Data}
              imgAlt={
                "A piece of paper sits on a desk with various graphs and charts drawn on it, showing different ways of communicating the data points"
              }
            ></ApproachStep>
            <ApproachStep
              title={"Developed high-level dashboard navigation"}
              activity={"Prototyping and User Testing"}
              step={4}
              description="There were challenges when creating the dashboard navigation. Some metrics were shared while others were individual - we needed to make sure that people could intuitively understand where they needed to go to find what they were looking for. We also needed this to work across business-wide, site-specific, and user-specific metrics. I prototyped a few different options for the high-level navigation and tested these with users."
              imgSrc={Nav}
              imgAlt={
                "A photo of various low-fidelity paper prototypes exploring different ways of handling the high level dashboard navigation"
              }
            ></ApproachStep>
            <ApproachStep
              title={"Tested dashboard views"}
              activity={"Prototyping and User Testing"}
              step={5}
              imgSrc={Testing}
              imgAlt={""}
              description="Once we'd nailed down the navigation, I pulled together insights from the previous activities to assemble specific dashboard views. I added metrics to the prototypes ordered by priority and put these in front of users for final testing. I validated how easy it was for users to navigate and locate their key metrics, checked if any metrics were missing, and confirmed the metrics were still easy to interpret when shown in the interface alongside other data."
            ></ApproachStep>
            <ApproachStep
              title={"Figma design handoff"}
              activity={"handoff"}
              step={6}
              description={
                "I used paper prototypes throughout the research because data presentation was such a barrier for users. I wanted to get honest feedback from them during our sessions if anything didn't make sense to them, and co-create better solutions. Now it was time for handoff. I created mid-level prototypes in Figma and collaborated with the in-house visual designer and tech lead to bring the designs to life. "
              }
              imgSrc={Finals}
              imgAlt={
                "A photo of a low fidelity paper prototype focused on the presentation and arrangement of metrics in the dashboard interface"
              }
            ></ApproachStep>
          </Approach>
          <Result>
            <p>
              The dashboard has been successfully built and is in use by the
              business today.
            </p>
            <p>
              It played a pivotal role in the business's rapid expansion,
              empowering the leadership team to:
              <ul>
                <li>make strategic decisions</li>
                <li>focus data resources on meaningful activities</li>
                <li>prove the case to investors to secure funding</li>
              </ul>
            </p>
          </Result>
        </Section>
      </Container>
      <div className={s.ProjectTestimonial}>
        <Section>
          <Container>
            <h2 className={s.TestimonialsTitle}>What did the client say?</h2>

            <TestimonialSmall
              description={
                "Robyn was a pleasure to work with. Her methodical approach made sure a complex project was delivered quickly, and her dashboard designs have brought transformative value to the Library of Things."
              }
              name={"Sophia"}
              role={"Director and Service Designer"}
              imgSrc={Soph}
              imgAlt={
                "Sophia is looking off camera and is smiling naturally and profusely"
              }
            ></TestimonialSmall>
          </Container>
        </Section>
      </div>
    </>
  );
};
