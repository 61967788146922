import { Container } from "../../components/Container/Container";
import { Section } from "../../components/Section/Section";
import s from "../../App.module.scss";
import { Approach } from "../../sections/Approach/Approach";
import { ApproachStep } from "../../sections/ApproachStep/ApproachStep";

import Secondary from "../../assets/images/projects/vmi/secondary.png";
import Eircodes from "../../assets/images/projects/vmi/eircodes.png";
import Journeys from "../../assets/images/projects/vmi/journeys.png";
import Expert from "../../assets/images/projects/vmi/expert.png";
import Wires from "../../assets/images/projects/vmi/wires.png";
import Final from "../../assets/images/projects/vmi/final.png";
import Stakeholders from "../../assets/images/projects/vmi/users.png";
import { Result } from "../../sections/Result/Result";

export const VirginMedia = () => {
  return (
    <>
      <Container>
        <p>
          Note: I worked with two very talented senior designers on this
          project. While I did the bulk of the thinking around the
          technicalities of the core journey and most of the core wireframing, I
          absolutely could not have done this - or created such a polished
          result! - without their help. Luiza really supported to think through
          trade-offs of the high level approaches and Emily handled all of the
          visual design polish and decks.
        </p>
      </Container>
      <Container>
        <Section>
          <Approach>
            <ApproachStep
              title={"Understand the business goals"}
              activity={
                "Kick off, stakeholder workshops, inspiration gathering workshop"
              }
              step={1}
              description={
                "We learned that our users included new customers and existing customers. New customers might still be in contract with another supplier when researching and people who are ready to buy are likely return visitors. Existing customers could also want to learn more about new products or upgrades or might be comparing with other offerings elsewhere. We learned that our core journeys included new customers learning or building a package, and existing customers upgrading. We learned that new customers need help understanding Virgin's general offering and assessing fit, while existing customers need help remembering what they already have and seeing how changes would affect their bundle. We also learned about the different emotional states we want to induce throughout (learning: interested / confident / reassured / calm, building: clear, valued, in control, excited, existing: focused, valued, understood, recognised) We also asked stakegolders to proveide examples of their ideas and inspiration for consideration. "
              }
              imgSrc={Stakeholders}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={"Understand user needs and pain points"}
              activity={
                "Look at previous research and projects, review survey data, interview sales rep, listened to sales calls"
              }
              step={2}
              description={
                "We learned that new customers found ordering online easier than existing customers. New customers wanted more information about products and greater clarity around delivery and installation, and they want to be able to call someone if they get stuck. Existing customers need clearer information about how adding or removing products will affect their plan and when. There were frequent complaints about struggling to get the information they needed, frustration when trying to phone, and a general sense of a lack of clarity and mismatched expectations. We also learned that reliability and quality of internet service are the leaading reasons people leave, although some have no choice (like when they move to a certain area). Few mentioned cost as a factor - in fact, people are willing to pay for good service. Loyalty is low. Customers don't understand what speed fits their needs, feel a lack of clarity generally, struggle with navigability, and don't understand what's next. They are confused about activation vs installation fees and whether or not to book a technician. They don't know what is included in their bundles (eg: tv channels) and feel everything is spread all over and they have to hunt for it. "
              }
              imgSrc={Secondary}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={"Understand product dependencies"}
              activity={
                "Map out 'one journey', map out product offering + add-ons, and consider legacy products. Try out a few approaches"
              }
              step={3}
              description={
                "We knew that users were having a hard time navigating the buying journey and understanding what comes with what, how deals are  built, and what comes next. We thought that it might be good for us to build one comprehensive buying journey that guides the user through each step, but we needed to explore if this was technically feasible and also if this would work well for customers who are just learning about Virgin's products and aren't yet ready to commit to builkding a package. We needed to understand how many products are on offer, how they combine to form deals, and what complications might arise in this format vs a standard product-oriented journey to assess the feasibility. "
              }
              imgSrc={Journeys}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={"Understand tech constraints"}
              activity={"Address check logic and dependencies"}
              step={4}
              description={
                "There were also technical challenges that could limit what is possible. For example, Virgin Media can only tell you what service is available to you based on your address, but Ireland is only just now rolling out 'eircodes' (postcodes). This means that many users may not know their formal address and / or are likely to enter their address incorrectly. We needed to find ways to ensure that customers are able to explore products that are available to them without blocking them too early on, and to provide excellent error recovery, alternatives, and support to users at the point that we really do need this information. Additionally, Virgin Media has some major changes to infrastrcuture planned for the future which could impact our designs, so we needed to learn more about that to ensure our designs could work now and in the future. "
              }
              imgSrc={Eircodes}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={"Ideas to improve painful moments"}
              activity={
                "Expert review, competitor / comparator review, journey mapping, lose wires"
              }
              step={5}
              description={
                "We had a good sense of our user needs, pain points, product offering, and constraints. Now it was time to start exploring how we might pull all of these findings into an improved experience for our users. We looked at competitors like eir, vodafone, Virgin Media UK, etc, as well as comparators like Apple, Sky, and Lemonade to see how other companies handle similar challenges. We were particularly interested in learning more about routes in to buying, navigation, content, tone, and if a 'one journey' approach might work. This brought to light a challenge for us around communication: no matter what product you want to buy you HAVE to have internet with Virgin Media in order to do so (incl TV channels or phones). This made the need for a more guided journey even more convincing. Interesting ideas included conversational shopping where you shop by answering questions, etc. "
              }
              imgSrc={Expert}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={"Wireframe 'One Journey' solution"}
              activity={"wireframing"}
              step={6}
              description={
                "Given the inherent complexity of building a package with Virgin Media, we decided that the One Journey approach would be most suitable for users. This gave us the opportunity to guide users through the path of chrecking internet before shopping around regardless of what product they are after, and allows us to ensure that customers could access what they needed despite eircode constraints. We could ensure a really clear progression and make the experience feel super personal and confident for them. "
              }
              imgSrc={Wires}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={"Hand over to visual design"}
              activity={"visual design"}
              step={7}
              description={
                "We didn't have any budget from VMI for user testing as their call centre staff were on strike for the forseeable and they needed to offer a better experience for users now. We flagged the risks to this approach, but they were happy to watch analytics and respond if it looked worse. In light of this, Emily polished up our wires into some really stunning high-fidelity visual designs and incredsibly well presented design decks to hand-over to the client!"
              }
              imgSrc={Final}
              imgAlt={""}
            ></ApproachStep>
          </Approach>
          <Result>
            <p>A new shopping journey that:</p>
            <ul>
              <li>
                allows users to browse products freely without having to add
                their address first
              </li>
              <li>
                enables users to browse and compare all channels included acros
                various TV packages
              </li>
              <li>
                Seperates the extras that require a bit more thought and
                decision making into their own mini step and then returns them
                back to the main extras page
              </li>
              <li>
                automatically checks to see if the customer already has a virgin
                media socket installed in their house, prompting the user to
                check if they need a technician if uncertain
              </li>
              <li>
                Warns the customer if we know they are going to need to have a
                technician install their kit for them
              </li>
              <li>
                Super robust address checking functionality: checks if the
                address already has services, if deals are not available at that
                address, no services available at that address, and allows the
                customer to change their address in the middle of building a
                package. Address not found. Also allows the user to use their
                strees address instead of eircode if eircode not known or not
                working. Clear error states provided to help the user understand
                why their eircode has failed.
              </li>
              <li>
                Very clear basket summary with an expanded basket available for
                more detail. Accessible throughout the buying journey once a
                product has been addeed.
              </li>
              <li>
                Clear step navigation available throughout the package builder
                journey, indicating progress but also letting the user toggle
                between steps.
              </li>
              <li>
                Clear reminder of the onward journey once the user enters
                checkout.
              </li>
              <li>
                Maing it so customers can move out of the package builder and
                back in
              </li>
              <li>
                Exciting visuals and content to keep the user energised and
                excited throughout, especially at 'annoying' steps like address
                check ("which VMI services can you get vs check what deals are
                avaialble at your postcode")
              </li>
              <li>
                Clear wording 'it all starts with broadband' at the start of the
                journey to remind users they have to have internet for any
                product.
              </li>
              <li>
                Product options clearly described by lifestyle they suit - no
                need for technical jargon! Eg: "best for avid gamers", "best for
                occasional surfing:"
              </li>
              <li>
                Technical details available for those who 'get' them, but
                otherwise collapsed with a focus on plain english descriptions.
              </li>
              <li>
                Make PAYG options clearer and surface the 'fine print'
                transparentlty up front with alternatives shown.
              </li>
              <li>
                Changed product names in basket to be human readable - you know
                what's a core product vs an add-on and the type of product it is
                easily (not having to recall crazy product names!)
              </li>
              <li>
                Clearly calls out what is included, what deals are applied, and
                what your costts wil be over time based on contract terms
              </li>
            </ul>
          </Result>
        </Section>
      </Container>
      <div className={s.ProjectTestimonial}>
        <Section>
          <Container>
            <h2 className={s.SectionTitle}>Testimonials</h2>
            <div className={s.TestimonialWrapper}>
              <div className={s.ReviewWrapper}>
                <p className={s.Review}>
                  "What shone through most to me is that Robyn really cares
                  about doing a good job - she really cares about delivering
                  high-quality thinking, UX, and design recommendations. She can
                  dissect a problem in a lot of detail in order to make really
                  considered UX decisions and this was really valuable when we
                  were mapping out user journeys, flows, and processes - this is
                  something we as an agency should really be taking advantage of
                  as it's a huge weapon in our arsenal. She also has a natural
                  authority which can come across really well in client
                  presentations and instills a lot of trust from clients. Robyn
                  also has good wireframing skills and I really enjoyed our
                  chats in between meetings too which was lovely! "
                </p>
                <p className={s.Reviewer}>- Luiza, Experience Designer</p>
              </div>
              <div className={s.ReviewWrapper}>
                <p className={s.Review}>
                  "Robyn was thrown in the deep end with a fast-paced,
                  high-profile client and went at it with enthusiasm and
                  positivity. I found Robyn to be kind, friendly, and a great
                  colleague to work with. Along the way we got some great
                  feedback from the client about her work, and her name
                  frequntly croped up in meetings with higher-ups to credit her
                  ideas which I think says a lot. She excels at getting into the
                  details and explore all of the scenarios and edge-cases with
                  ease; as a result of this, we were even able to crack some
                  tough problems that their technical team had been scratching
                  their head over for years because she spotted some areas where
                  we could tie up processes. She's also great at getting
                  involved in collaborative discussions. She was engaged and
                  unaffraid to challenge ideas and offer opinions. She had some
                  great ideas to put forward herself and was passionate about
                  the project and championing the end users.""
                </p>
                <p className={s.Reviewer}>- Emily, Senior Designer</p>
              </div>
            </div>
          </Container>
        </Section>
      </div>
    </>
  );
};
