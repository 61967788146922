import s from "./TestimonialSmall.module.scss";

interface PropsT {
  description: string;
  name: string;
  role: string;
  imgSrc: string;
  imgAlt: string;
}

export const TestimonialSmall = ({
  description,
  name,
  role,
  imgSrc,
  imgAlt,
}: PropsT) => {
  return (
    <>
      <div className={s.TestimonialFancyWrapper}>
        <div
          className={s.TestimonialFancyPhoto}
          style={{ backgroundImage: `url(${imgSrc})` }}
          role="img"
          aria-label={imgAlt}
        ></div>
        <div className={s.TestimonialFancy}>
          <p className={s.TestimonialFancyBody}>"{description}"</p>
          <p className={s.Reviewer}>
            - {name}, {role}
          </p>
        </div>
      </div>
    </>
  );
};
