import { Section } from "../../components/Section/Section";
import { Container } from "../../components/Container/Container";
import s from "../../App.module.scss";
import { ProjectHeader } from "../../components/ProjectHeader/ProjectHeader";
import { ApproachStep } from "../../sections/ApproachStep/ApproachStep";
import { TestimonialSmall } from "../../sections/TestimonialSmall/TestimonialSmall";
import { Approach } from "../../sections/Approach/Approach";

import Contentful from "../../assets/images/projects/ecc/contentful.png";
import Drupal from "../../assets/images/projects/ecc/drupal.png";
import Expert from "../../assets/images/projects/ecc/expert.png";
import Plan from "../../assets/images/projects/ecc/plan.png";
import Prototype1 from "../../assets/images/projects/ecc/prototypes.png";
import Prototype2 from "../../assets/images/projects/ecc/prototype2.png";
import Report from "../../assets/images/projects/ecc/report.png";
import Stakeholders from "../../assets/images/projects/ecc/stakeholders.png";
import Stories from "../../assets/images/projects/ecc/stories.png";
import Tasks from "../../assets/images/projects/ecc/tasks.png";
import Testing from "../../assets/images/projects/ecc/testing.png";

import EccImage from "../../assets/images/projects/ecc.jpeg";
import { Result } from "../../sections/Result/Result";

import Jamie from "../../assets/images/team/jamie.jpeg";
import Alex from "../../assets/images/team/alex.jpeg";
import Keelan from "../../assets/images/team/keelan.jpeg";
import Matt from "../../assets/images/team/matt.jpg";
import Frank from "../../assets/images/team/frank.jpeg";
import Bob from "../../assets/images/team/businessman2.jpeg";

export const Ecc = () => {
  return (
    <>
      <ProjectHeader
        image={EccImage}
        title={
          "Help the council and their constituents move to a new content management system."
        }
        intro={"Essex County Council, 2022"}
      ></ProjectHeader>
      <div className={s.Callout}>
        <Section>
          <Container>
            <p className={s.CalloutText}>"Quote"</p>
            <p>- Source, Role</p>
          </Container>
        </Section>
      </div>

      <Section className={s.Background}>
        <Container>
          <h2 className={s.Subtitle}>Project Background</h2>
          <p className={s.FirstP}>TBD</p>
          <div className={s.Challenge}>
            <h2 className={s.Subtitle}>The Challenge: </h2>
            <ul className={s.FirstP}>
              <li>TBD</li>
            </ul>
          </div>
        </Container>
      </Section>
      <Container>
        <Section>
          <Approach>
            <ApproachStep
              title={"Learned about our project goals and scope"}
              activity={"Stakeholder Interviews + Playback workshop"}
              step={1}
              description={""}
              imgSrc={Stakeholders}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={
                "Identified key journeys and user groups to explore in both CMSs"
              }
              activity={""}
              step={3}
              description={"Journey mapping and task analysis"}
              imgSrc={Tasks}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={"Prepared a research plan"}
              activity={"Research Planning"}
              step={3}
              description={""}
              imgSrc={Plan}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={
                "Learned about what does / doesn't work well in the current CMS"
              }
              activity={"User Interviews"}
              step={4}
              description={""}
              imgSrc={Contentful}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={
                "Learned about what does / doesn't work well in the new CMS"
              }
              activity={"User Interviews"}
              step={4}
              description={""}
              imgSrc={Drupal}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={"Captured all user needs for the new CMS"}
              activity={"User Story Generation"}
              step={6}
              description={""}
              imgSrc={Stories}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={
                "Prioritised + handed over all requirements for the new CMS"
              }
              activity={"Prioritisation Workshop"}
              step={6}
              description={""}
              imgSrc={Stories}
              imgAlt={""}
            ></ApproachStep>
          </Approach>
          <Result>
            <p>test</p>
          </Result>
        </Section>
        <Container>
          <Section>
            <Approach>
              <ApproachStep
                title={"Did an expert review of the public-facing website"}
                activity={""}
                step={6}
                description={""}
                imgSrc={Expert}
                imgAlt={""}
              ></ApproachStep>
              <ApproachStep
                title={
                  "Identified in-scope research questions to test with the public"
                }
                activity={""}
                step={7}
                description={""}
                imgSrc={Prototype2}
                imgAlt={""}
              ></ApproachStep>
              <ApproachStep
                title={"Hacked together a prototype to test with the public"}
                activity={""}
                step={7}
                description={""}
                imgSrc={Prototype1}
                imgAlt={""}
              ></ApproachStep>
              <ApproachStep
                title={"Planned my research with the public"}
                activity={""}
                step={7}
                description={""}
                imgSrc={Plan}
                imgAlt={""}
              ></ApproachStep>
              <ApproachStep
                title={"Tested the public-facing website with residents"}
                activity={""}
                step={8}
                description={""}
                imgSrc={Testing}
                imgAlt={""}
              ></ApproachStep>
              <ApproachStep
                title={"Made final recommendations"}
                activity={""}
                step={8}
                description={""}
                imgSrc={Report}
                imgAlt={""}
              ></ApproachStep>
            </Approach>
            <Result>
              <p>Test</p>
            </Result>
          </Section>
        </Container>
      </Container>
      <div className={s.ProjectTestimonial}>
        <Section>
          <Container>
            <h2 className={s.TestimonialsTitle}>What did the client say?</h2>

            <TestimonialSmall
              description={
                "Robyn was a fantastic welcome to Nomensa for me. My first task was translating Robyn's fantastic research into development tasks. She was an amazing teammate - so welcoming, lovely to work with, and diligent in her work. The quality and content were actually a little scary - a very early introduction to the amazing standards of some Nomensans! She even took the time to actively gather feedback on how she could improve any future potential handovers to me - to which I couldn't really say much. I felt fully equipped and supported to get cracking with the ECC project in no small part due to Robyn's efforts. "
              }
              name={"Jamie"}
              role={"Business Analyst"}
              imgSrc={Jamie}
              imgAlt={""}
            ></TestimonialSmall>
            <TestimonialSmall
              description={
                "Robyn is a great practictioner with bags of energy and is extremely competent in my opinion. She was great at communicating with others, both internally and externally, making really good relationships and fostering trust with the project team. She completed some great research within a very short window of opportunity, delivering research findings in an easy to digest and professional way. I hope to work with her again sometime as I really enjoyed her energy, process, and positive attitude. "
              }
              name={"Frank"}
              role={"Designer"}
              imgSrc={Frank}
              imgAlt={""}
            ></TestimonialSmall>
            <TestimonialSmall
              description={
                "Robyn is great to work with - I loved her enthusiasm! She always made sure to keep the user in mind in all scenarios, right from the start of unpicking research, to the user research sessions she held for Essex.gov. She was always an advocate for the users and made sure that the feedback from her research wasn't forgotten and pushed for it to be accounted for in the development work.   She made sure that the site was well prepared for user research sessions too. Robyn was super on top of keeping me in the loop, flagging any blockers and risks which was super appreciated as it meant the project ran a lot more smoothly. On top of that the client LOVED her! I can't wait to work with Robyn again in the future."
              }
              name={"Alex"}
              role={"Project Manager"}
              imgSrc={Alex}
              imgAlt={""}
            ></TestimonialSmall>
            <TestimonialSmall
              description={
                "Robyn was great on ECC and I hope to work with her again soon. She did a great job managing UX research in an unusual situation: it wasn't very clear what questions we needed to answer here, but Robyn's guidance really helped us narrow that down to useful information for the client. Her communications on the project were excellent, she kept everyone up to date on what was going on, making it really easy to represent her work and value to the end customer and to plan dependencies for her work."
              }
              name={"Matt"}
              role={"Lead Developer"}
              imgSrc={Matt}
              imgAlt={""}
            ></TestimonialSmall>
            <TestimonialSmall
              description={
                "Robyn was a great member of our project team. Especially in the early days of the early project, she really helped us to define what were looking to achieve. She advocated for our users all the time, and was confident challenging us when we needed to be challenged! Her positivity and enthusiasm was a huge contribution for the team. I was often amazed by her efficiency and how much she got done well and quickly. She was responsive to change along the way, including at short notice. I thoroughly enjoyed working with her and would be delighted to work with her again. "
              }
              name={"Keelan"}
              role={"Product Owner"}
              imgSrc={Keelan}
              imgAlt={""}
            ></TestimonialSmall>
            <TestimonialSmall
              description={
                "Robyn is very knowledgeable and highly competent in her specialism. She has been great at clearly communicating her views / ideas / findings and she is always prepared to speak out if she felt we weren't taking a sound user-centred approach. She is good at tackling difficult conversations in a constructive, professional, friendly, and approachable way. She was also heavily involved in helping us define our MVP scope, which I felt was quite a difficult exercise and Robyn was a great help with this. She also made huge contributions towards helping us learn how to better organsie and present our work internally and how we can work effectively as One Team. She was definitely an asset to the team!"
              }
              name={"Bob"}
              role={"Delivery Manager"}
              imgSrc={Bob}
              imgAlt={""}
            ></TestimonialSmall>
          </Container>
        </Section>
      </div>
    </>
  );
};
