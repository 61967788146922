// GENERAL IMPORTS
import s from "./ProjectCard.module.scss";
import { Link } from "react-router-dom";

// COMPONENT IMPORTS
import { ClientNameTag } from "../ClientNameTag/ClientNameTag";

interface Props {
  client: string;
  logo: string;
  alt: string;
  description: string;
  url: string;
  research?: boolean;
  wires?: boolean;
}

export const ProjectCard = ({
  client,
  logo,
  alt,
  description,
  url,
  research,
  wires,
}: Props) => {
  return (
    <Link to={url} className={s.UnsetLinkStyles}>
      <div className={s.CardStyles}>
        <div className={s.ClientSection}>
          <ClientNameTag src={logo} name={client} alt={alt} />
        </div>
        <div className={s.ProjectSection}>
          <p className={s.ProjectDescription}>{description}</p>
          <p className={s.Tags}>
            {research && <span className={s.Research}>user research </span>}
            {wires && <span className={s.Wires}>wireframing</span>}
          </p>
        </div>
      </div>
    </Link>
  );
};
