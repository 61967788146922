import { Section } from "../../components/Section/Section";
import { Container } from "../../components/Container/Container";
import s from "../../App.module.scss";

import { ProjectHeader } from "../../components/ProjectHeader/ProjectHeader";
import SageImage from "../../assets/images/projects/sage.jpeg";
import { useEffect } from "react";
import { Approach } from "../../sections/Approach/Approach";
import { ApproachStep } from "../../sections/ApproachStep/ApproachStep";
import { TestimonialSmall } from "../../sections/TestimonialSmall/TestimonialSmall";
import ExistingResearch from "../../assets/images/projects/sage/existingresearch.png";
import BestPractice from "../../assets/images/projects/sage/bestpractice.png";
import Challenged from "../../assets/images/projects/sage/challenge3.png";
import Plan from "../../assets/images/projects/sage/plan.png";
import Wires from "../../assets/images/projects/sage/wires.png";
import Test from "../../assets/images/projects/sage/test2.png";
import Recommendation from "../../assets/images/projects/sage/recommendations.png";
import Review from "../../assets/images/projects/sage/expert.png";

import Susan from "../../assets/images/team/susan.jpeg";
import Morgan from "../../assets/images/team/morgan.jpeg";
import Max from "../../assets/images/team/max.jpeg";
import Tom from "../../assets/images/team/tom.jpeg";
import { Result } from "../../sections/Result/Result";

export const Sage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ProjectHeader
        image={SageImage}
        title={
          "Empower customers to compare and select the best accounting products for their business"
        }
        intro={"Sage, 2023"}
      ></ProjectHeader>
      <div className={s.Callout}>
        <Section>
          <Container>
            <p className={s.CalloutText}>
              "The sessions were so insightful! Lots of gold nuggets in there.
              This proves the case for changing our approach to how we develop
              and market our products."
            </p>
            <p>- Susan, Head of Research at Sage</p>
          </Container>
        </Section>
      </div>

      <Section className={s.Background}>
        <Container>
          <h2 className={s.Subtitle}>Project Background</h2>
          <p className={s.FirstP}>
            Sage identified through extensive research and data analytics that
            potential business customers were struggling to comprehend Sage's
            product range and determine which product suited their needs. This
            had a significant impact on Sage's profit margins as potential
            customers were unable to reach a final purchasing decision and so
            did not buy.
          </p>

          <div className={s.Challenge}>
            <h2 className={s.Subtitle}>The Challenge: </h2>
            <p className={s.FirstP}>
              Sage tasked me with designing a new product category page that
              centered around a comparison table, showcasing all their products.
              The goal was to:
            </p>
            <ul>
              <li>enhance customer understanding of the products available</li>
              <li>simplify the decision-making process</li>
            </ul>
          </div>
        </Container>
      </Section>
      <Container>
        <Section>
          <Approach>
            <ApproachStep
              title={"Understood customer needs and pain points"}
              activity={"Research review, expert review"}
              step={1}
              description={
                "I reviewed Sage's existing research outputs, which identified persistent pain points in the shopping experience. These included a convoluted information structure, an extensive and unclear product catalog, too much marketing-speak and jargon, and confusing product names. "
              }
              imgSrc={Review}
              imgAlt={""}
            ></ApproachStep>
            {/* <ApproachStep
              title={"Evaluated the current shopping experience"}
              activity={"Expert Review"}
              step={2}
              description={""}
              imgSrc={Review}
              imgAlt={""}
            ></ApproachStep> */}
            <ApproachStep
              title={"Proposed a more holistic approach"}
              activity={"Secondary Research"}
              step={2}
              description={
                "While the initial request was to design a category page shown as a comparison table, research into UX best practices revealed that a comparison table wouldn't be suitable as an initial view. This was because Sage has too many products to be compared all in one go; the sheer volume of products would be overwhelming for users. Moreover, Sage's products were designed without uniform feature sets, making straightforward like-for-like comparisons impossible. A comparison table wouldn't address some of the wider navigation issues identified in the research, and I learned that the technical team couldn't implement a comparison table in the near term. I presented this information to the client and advocated for a more holistic approach. The client was thrilled that I went beyond the brief and interrogated the initial idea to ensure the best approach was taken."
              }
              imgSrc={BestPractice}
              imgAlt={""}
            ></ApproachStep>
            {/* <ApproachStep
              title={"Challenged the brief"}
              activity={""}
              step={4}
              description={""}
              imgSrc={Challenged}
              imgAlt={""}
            ></ApproachStep> */}
            <ApproachStep
              title={"Designed an intuitive interface informed by insights"}
              activity={"Prototyping, research planning"}
              step={3}
              description={
                "To address the identified pain points and technical limitations, I designed a new category page.  It featured a tabbed interface for various product types, making Sage's full product range immediately clear. I also incorporated filters, enabling customers to refine their choices according to their specific requirements. I provided comprehensive product descriptions to facilitate clear differentiation in the absence of distinct product names. A significant enhancement was the introduction of a two-step process, allowing users to initially narrow their selections using filters and subsequently compare shortlisted products if desired. This design considerably improved the user experience in the short term but also carefully considered the potential of a comparison table in the future, laying the groundwork for its integration when feasible. In collaboration with another designer, I also devised a new information architecture to complement this solution."
              }
              imgSrc={Wires}
              imgAlt={""}
            ></ApproachStep>
            {/* <ApproachStep
              title={"Planned research"}
              activity={""}
              step={6}
              description={""}
              imgSrc={Plan}
              imgAlt={""}
            ></ApproachStep> */}
            <ApproachStep
              title={"Validated designs through user testing"}
              activity={"Treejack testing and usability testing"}
              step={4}
              description={
                "I planned and facilitated 17 research sessions with small businesses, medium businesses, and resellers. These sessions included treejack testing for the new information architecture and usability testing with wireframes. The results were overwhelmingly positive, with valuable feedback regarding some additional filters that customers would find helpful and the presentation of information in the comparison table."
              }
              imgSrc={Test}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={
                "Made strategic recommendations based on synthesized insights"
              }
              activity={
                "Research synthesis, analysis, reporting, and presenting"
              }
              step={5}
              description={
                "We presented our findings to the client and recommended several key actions. This included adopting a more strategic approach to product development to make the additional comparison table functionality possible in the future. We also suggested refining their naming strategy at both the category and product levels to enhance user comprehension and improve website search functionality."
              }
              imgSrc={Recommendation}
              imgAlt={""}
            ></ApproachStep>
          </Approach>
          <Result>
            <ul>
              <li>
                Highlighted the necessity for a more strategic approach to
                product development and naming conventions
              </li>
              <li>
                Delivered an immediate solution while simultaneously laying the
                groundwork for future integration of comparison functionality.
              </li>
              <li>
                Successfully improved customer understanding and simplified the
                decision-making process
              </li>
              <li>
                Wireframes passed on to Sage's internal visual design team for
                polishing and implementation
              </li>
            </ul>
          </Result>
        </Section>
      </Container>
      <div className={s.ProjectTestimonial}>
        <Section>
          <Container>
            <h2 className={s.TestimonialsTitle}>What did the client say?</h2>

            <TestimonialSmall
              description={
                "Robyn had a really positive attitude and wasn't afraid to ask questions and challenge decisions when needed. Her work on the wireframes was great too - she has a good intuition for creating them and took care to really think through the requirements and ensure that our previous work fed into her decisions. She was great in our usability testing sessions as well; she has a nice manner with participants and got a good level of information and detail as required. She was able to work through technical challenges without getting phased and her calm approach kept things balanced throughout the project. "
              }
              name={"Max"}
              role={"Principal UX Consultant"}
              imgSrc={Max}
              imgAlt={""}
            ></TestimonialSmall>

            <TestimonialSmall
              description={
                "The sessions were so insightful! Lots of gold nuggets in there. This proves the case for changing our approach to how we develop and market our products."
              }
              name={"Susan"}
              role={"Head of Research"}
              imgSrc={Susan}
              imgAlt={""}
            ></TestimonialSmall>
            <TestimonialSmall
              description={
                "Robyn smashed it! I've never seen the client smile so much - she was clearly very impressed with the solution. Excellent work."
              }
              name={"Morgan"}
              role={"Account Director"}
              imgSrc={Morgan}
              imgAlt={""}
            ></TestimonialSmall>
            <TestimonialSmall
              description={
                "Robyn helped me loads with my note-taking during research sessions. She gave me some really good feedback and helped me completely rethink how I go in to projects in the future and how I can help myself later in analysis. I am so impressed with how well she facilitated the interviews and how much information she can take in, record, and synthesize on the spot whilst facilitating too - I can't wait to keep learning! Thank you, Robyn. "
              }
              name={"Tom"}
              role={"Graduate UX Consultant"}
              imgSrc={Tom}
              imgAlt={""}
            ></TestimonialSmall>
          </Container>
        </Section>
      </div>
    </>
  );
};
