// GENERAL IMPORTS
import s from "../../App.module.scss";

// COMPONENT IMPORTS
import { Section } from "../../components/Section/Section";
import { Container } from "../../components/Container/Container";
import { useEffect } from "react";
import { Approach } from "../../sections/Approach/Approach";
import { ApproachStep } from "../../sections/ApproachStep/ApproachStep";
import { Result } from "../../sections/Result/Result";
import { ProjectHeader } from "../../components/ProjectHeader/ProjectHeader";
import { TestimonialSmall } from "../../sections/TestimonialSmall/TestimonialSmall";

// PHOTO IMPORTS
import Katharine from "../../assets/images/katharine.jpeg";
import Faisal from "../../assets/images/team/Faisal.jpeg";
import Chris from "../../assets/images/team/chris.jpeg";
import Graeme from "../../assets/images/team/Graeme.jpeg";
import Dil from "../../assets/images/team/Dil.jpeg";
import Drivers from "../../assets/images/projects/drivers2.png";
import Scope from "../../assets/images/projects/scope.png";
import Effort from "../../assets/images/projects/effort6.png";
import Time from "../../assets/images/projects/time2.png";
import Apprentice from "../../assets/images/projects/dfe.jpeg";
import BusinessMan from "../../assets/images/team/businessman2.jpeg";
import Report from "../../assets/images/projects/report.png";

export const Dfe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ProjectHeader
        image={Apprentice}
        title={
          "Increase the number of apprentices who complete their apprenticeship"
        }
        intro={"The Department for Education, 2023"}
      ></ProjectHeader>

      <div className={s.Callout}>
        <Section>
          <Container>
            <p className={s.CalloutText}>
              "I think the way you've approached this problem is brilliant. Your
              approach is just so logical and airtight you actually can't argue
              with it"
            </p>
            <p>
              - Graeme Rutherford, Senior Service Designer at the Department for
              Education
            </p>
          </Container>
        </Section>
      </div>
      <Section className={s.Background}>
        <Container>
          <h2 className={s.Subtitle}>Project Background</h2>
          <p className={s.FirstP}>
            The Department for Education came under public scrutiny after the
            BBC publicized that most apprentices don't finish their
            apprenticeship. With general elections looming, Number 10 mandated
            rapid improvements.
          </p>
          <p>
            The Department was set an ambitious target: improve the apprentice
            achievement rate by 15% in 2 years.
          </p>
          <div className={s.Challenge}>
            <h2 className={s.Subtitle}>The Challenge: </h2>
            <ul className={s.FirstP}>
              <li>
                Understand why apprentices don't complete their apprenticeship
              </li>
              <li>
                Select 1 - 3 high-impact reasons for non-completion to take into
                full discovery
              </li>
            </ul>
          </div>
        </Container>
      </Section>
      <Container>
        <Section>
          <Approach>
            <ApproachStep
              title={"Identified top reasons for apprentice non-completion"}
              activity={"Secondary Research Review"}
              step={1}
              description={
                "I reviewed secondary research and extracted the top reasons that apprentices do not finish their apprenticeship. We de-scoped any reasons that were out of the service's control or represented positive outcomes for the apprentice, such as being offered another job at higher pay. "
              }
              imgSrc={Drivers}
              imgAlt={
                "Robyn's miro board showing all of the pain points extracted from her research on post-it notes"
              }
            ></ApproachStep>
            <ApproachStep
              title={"Checked which reasons were in-scope"}
              activity={"Stakeholder Interviews"}
              step={2}
              description={
                "Some of the reasons for non-completion could only be addressed with new technology or policy. We only had two years, so I needed to work with stakeholders and the Product Owner to understand what was achievable. We also learned that solving some of these problems could have negative knock-on effects for disadvantaged groups. For example, many health and care apprentices dropout because they need to care for relatives. If we penalize employers in this sector for low completion they might pull the courses altogether."
              }
              imgSrc={Scope}
              imgAlt={
                "Robyn's miro board, showing some post-its which have been de-scoped"
              }
            ></ApproachStep>
            <ApproachStep
              title={
                "Identified reasons that would affect dropout rate within two years"
              }
              activity={"Internal data analysis"}
              step={3}
              description={
                "We had met our objective of understanding why apprentices do not complete overall, but we still didn’t know which of these reasons would have an impact within our short 2 year timeframe. To answer this, I worked with our technical architect and business analyst to look at when most dropouts occur. We saw that 30% of withdrawals occur within the first 3 months. We also saw that some pain points only occur at the end of very long-term apprenticeships and as a result would not have much impact on completion rates within the next 2 years. Some pain points had a high impact across the full journey, making them good candidates for our short-term goals and the apprenticeship experience overall. "
              }
              imgSrc={Time}
              imgAlt={
                "Robyn's miro board showing how different pain points align across the user journey"
              }
            ></ApproachStep>
            <ApproachStep
              title={"Prioritized remaining reasons"}
              activity={"Ideation workshop and effort-impact matrix"}
              step={4}
              description={
                "I facilitated a prioritization and ideation workshop with the team. This allowed us to identify further constraints and research questions to be explored further in discovery. We gained team alignment around the top 3 reasons for non-completion to take forward. "
              }
              imgSrc={Effort}
              imgAlt={"Robyn's miro board showing an effort-impact matrix"}
            ></ApproachStep>
            <ApproachStep
              title={"Made final recommendations"}
              activity={"Report-writing and presenting"}
              step={5}
              description={
                "I presented my findings to senior stakeholders and gained approval to move into discovery. Our top three non-completion reasons to investigate in discovery were: a lack of employer support, a high workload, and a mismatch in apprentice expectations vs reality."
              }
              imgSrc={Report}
              imgAlt={"A screenshot of Robyn's final report deck cover"}
            ></ApproachStep>
          </Approach>
          <Result>
            <ul>
              <li>
                Identified the top reasons that apprentices do not complete
                their apprenticeship
              </li>
              <li>
                Prioritized the most-impactful in-scope problem statements to
                bring forwards
              </li>
              <li>Obtained approval to move in to a full discovery</li>
              <li>Delivered the project on-time and on-budget</li>
            </ul>
          </Result>
        </Section>
      </Container>
      <div className={s.ProjectTestimonial}>
        <Section>
          <Container>
            <h2 className={s.TestimonialsTitle}>What did the client say?</h2>

            <TestimonialSmall
              description={
                "Robyn's work is brilliant. Among a team of excellent Nomensans, she is absolutely phenomenal and a force to be reckoned with. We are lucky to have her on our team!"
              }
              name={"Katharine"}
              role={"Senior Interaction Designer"}
              imgSrc={Katharine}
              imgAlt={
                "Katharine looks straight at the camera, smiling. She has brown hair with a fringe, black-rimmed glasses, and a black blouse to match"
              }
            ></TestimonialSmall>
            <TestimonialSmall
              description={
                "Robyn's work on this pre-discovery is outstanding. It's great seeing such a data-led and analytical approach. This is the most thorough, robust piece of research I've seen done, not just from a research perspective but also how the findings have been communicated back so simply."
              }
              name={"Jason"}
              role={"Director of Operations"}
              imgSrc={BusinessMan}
              imgAlt={
                "An illustrated avatar showing a business man with short black hair, black rimmed glasses, a grey suit jacket, and with a bright coral tie."
              }
            ></TestimonialSmall>
            <TestimonialSmall
              description={
                "Robyn handled the quality strategy work and UR like a boss!"
              }
              name={"Dil"}
              role={"Business Analyst"}
              imgSrc={Dil}
              imgAlt={
                "Dil is smiling at the camera with his head tilted slightly. He is bald with a trimmed goatee and is wearing a grey turtleneck jumper with a black blazer over it"
              }
            ></TestimonialSmall>

            <TestimonialSmall
              description={
                "The ideation session was so much fun and I found it super useful to get to talk about all of our ideas and approach together. I wish we could do that every time!"
              }
              name={"Chris"}
              role={"Lead Developer"}
              imgSrc={Chris}
              imgAlt={
                "A cartoon illustration of Chris, showing his brown hair, thin black rimmed glasses, and classic button up shirt. His brow is furrowed with energy emmanating from his head"
              }
            ></TestimonialSmall>

            <TestimonialSmall
              description={
                "I think the way you've approached this problem is brilliant. This is such a messy and complicated problem space, I was really worried that it would get mishandled, but you're asking all the right questions. Your approach is just so logical and airtight you actually can't argue with it, and it's great to see the service finally reconsidering its relationship with employers and providers now because of that. I'm really impressed, really well done"
              }
              name={"Graeme"}
              role={"Senior Service Designer"}
              imgSrc={Graeme}
              imgAlt={
                "A black and white photo of Graeme. Graeme is smiling as though he is mid-chuckle with his hair parted off to one side. He has a beard and is wearing a simple black T shirt. "
              }
            ></TestimonialSmall>

            <TestimonialSmall
              description={
                "Robyn absolutely smashed it out of the park! Great work!"
              }
              name={"Faisal"}
              role={"Product Owner"}
              imgSrc={Faisal}
              imgAlt={
                "Faisal is smiling at the camera. He has short black hair and a beard. He is wearing a lavendar button up shirt with a grey suit jacket. A marquee can be seen behind him."
              }
            ></TestimonialSmall>
          </Container>
        </Section>
      </div>
    </>
  );
};
