import s from "./ClientReel.module.scss";
import { Container } from "../../components/Container/Container";
import VirginMedia from "../../assets/images/logos/white/vmi.png";
import TheInsolvencyService from "../../assets/images/logos/white/tis.png";
import NaturalEngland from "../../assets/images/logos/white/defra.png";
import Deliveroo from "../../assets/images/logos/white/deliveroo.png";
import LibraryOfThings from "../../assets/images/logos/white/lot.png";

export const ClientReel = () => {
  return (
    <div className={s.PrimarySection}>
      <Container>
        <section>
          <h2 className={s.Hidden}>Client Logo Reel</h2>
          <div className={s.ReelCarousel}>
            <img
              src={VirginMedia}
              alt={"Virgin Media logo"}
              className={s.Logo}
            />
            <img
              src={TheInsolvencyService}
              alt={"Insolvency Service logo"}
              className={s.Logo}
            />
            <img src={Deliveroo} alt={"Deliveroo logo"} className={s.Logo} />
            <img
              src={NaturalEngland}
              alt={"Department for Environment, Food, and Rural Affairs logo"}
              className={s.Logo}
            />
            <img
              src={LibraryOfThings}
              alt={"Library of Things logo"}
              className={s.Logo}
            />
          </div>
        </section>
      </Container>
    </div>
  );
};
