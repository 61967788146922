// GENERAL IMPORTS
import s from "./FancyHeader.module.scss";
import cn from "classnames";
import React from "react";

// COMPONENT IMPORTS
import { Container } from "../../components/Container/Container";

interface PropsT {
  image1src?: string;
  image1alt?: string;
  image2src?: string;
  image2alt?: string;
  image3src?: string;
  image3alt?: string;
  title?: string;
  copy?: string;
  hiddenH2?: string;
}

export const FancyHeader = ({
  title,
  copy,
  image1src,
  image2src,
  image3src,
  image1alt,
  image2alt,
  image3alt,
  hiddenH2,
}: PropsT) => {
  return (
    <div className={s.SectionWrapper}>
      <div className={s.DesktopImagesLayoutWrapper}>
        <div className={s.LeftColumn}>
          <div
            role="img"
            aria-label={image1alt}
            className={cn(s.GridImage, s.FeaturedImage)}
            style={{ backgroundImage: `url(${image1src})` }}
          ></div>
        </div>
        <div className={s.RightColumn}>
          <div
            role="img"
            aria-label={image2alt}
            className={cn(s.GridImage, s.SecondaryImage)}
            style={{ backgroundImage: `url(${image2src})` }}
          ></div>
          <div
            role="img"
            aria-label={image3alt}
            className={cn(s.GridImage, s.SupplementalImage)}
            style={{ backgroundImage: `url(${image3src})` }}
          ></div>
        </div>
      </div>
      <Container>
        <div className={s.ContentContainer}>
          <div className={s.CopyContainer}>
            <section>
              <h2 className={s.Hidden}>{hiddenH2}</h2>
              <p className={s.Title}>Hi! &#128075; {title}</p>
              <p className={s.Body}>{copy}</p>
            </section>
          </div>
        </div>
      </Container>
      <div className={s.MobileImagesLayoutWrapper}>
        <Container>
          <div className={s.MobileImagesLayout}>
            <img
              src={image1src}
              alt={image1alt}
              className={cn(s.MobileImage)}
            />
            <img
              src={image2src}
              alt={image2alt}
              className={cn(s.MobileImage)}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};
