import "./App.module.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Page imports
import { Nav } from "./components/Nav/Nav";
import { Home } from "./pages/home";
import { Dfe } from "./pages/case-studies/dfe";
import { Dyson } from "./pages/case-studies/dyson";
import { Sage } from "./pages/case-studies/sage";
import { VirginMedia } from "./pages/case-studies/virginmedia";
import { LibraryOfThings } from "./pages/case-studies/libraryofthings";
import { AboutMe } from "./pages/aboutMe";
import { Footer } from "./sections/Footer/Footer";
import { Ecc } from "./pages/case-studies/ecc";

function App() {
  return (
    <div className="App">
      <header></header>
      <Nav />
      <main>
        <Router>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about-me" exact component={AboutMe} />
            <Route path="/dyson" exact component={Dyson} />
            <Route path="/department-for-education" exact component={Dfe} />
            <Route path="/sage" exact component={Sage} />
            <Route path="/essex-county-council" exact component={Ecc} />
            <Route path="/virgin-media-ireland" exact component={VirginMedia} />
            <Route
              path="/the-library-of-things"
              exact
              component={LibraryOfThings}
            />
          </Switch>
        </Router>
      </main>
      <Footer />
    </div>
  );
}

export default App;
