import React from "react";
import cn from "classnames";
import style from "./Section.module.scss";

interface PropsT {
  children: React.ReactNode;
  className?: string;
  pro?: boolean;
  primary2?: boolean;
  primary3?: boolean;
  primary4?: boolean;
}

export const Section = ({
  className,
  children,
  pro,
  primary2,
  primary3,
  primary4,
}: PropsT) => {
  return (
    <section
      className={cn(style.Section, className, {
        [style.Section_pro]: pro,
        [style.Section_secondary]: primary2,
        [style.Section_tertiary]: primary3,
        [style.Section_four]: primary4,
      })}
    >
      {children}
    </section>
  );
};
