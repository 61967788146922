// GENERAL IMPORTS
import React from "react";
import s from "./Testimonials.module.scss";

// COMPONENT IMPORTS
import { Container } from "../../components/Container/Container";

interface Props {
  image1: string;
  content?: string;
  thingImage?: string;
  personImage?: string;
  author?: string;
  largeImage?: string;
  smallImage?: string;
}

export const Testimonials = ({ image1 }: Props) => {
  return (
    <div className={s.SectionStyle}>
      <Container>
        <section>
          <h2 className={s.Title}>What my clients say</h2>
          <div className={s.QuoteWrapper}>
            <p className={s.TestimonialLead}>
              "I can’t say emphatically enough how much I enjoyed working with
              Robyn in Apprenticeship Services. I am constantly astounded by her
              abilities and genuinely look forward to every meeting I have with
              her.
            </p>
            <p>
              For context, I joined her delivery team at the end of a discovery.
              Robyn had been a significant driving force in the initial phases,
              navigating the pressure with apparent ease. She had analysed an
              immense amount of secondary research, talked with an enormous
              number of stakeholders, and essentially crafted the team’s entire
              strategy going forward as a result. She’d played back all these
              results in beautiful, well-presented decks and involved the team
              in great ideation sessions. When I joined, she onboarded me to the
              project in an incredibly useful and clear way.
            </p>
            <p>
              During our work together, the things that impressed me the most
              about Robyn included her intelligence, speed, confidence, and
              expertise. She's easily the best user researcher I've met - it
              truly feels like a joy to her and not a tickbox exercise and it
              shows. She's also fantastic and so multitalented - she leapt in
              and out of design work to make the UR really come to life and
              suggested brilliant ideas - it felt like UR was finally a core
              part of our UCD team, not a standalone unit, which was great.
            </p>
            <p className={s.Standout}>
              "During our work together, the things that impressed me the most
              about Robyn included her intelligence, speed, confidence, and
              expertise. She's easily the best user researcher I've met"
            </p>
            <p>
              One of the things that set Robyn aside is the way she processes
              knowledge with such speed. It's difficult to explain in words as
              it's more something you sense when you meet someone - but for one
              example, during research sessions she will put people at ease
              instantly, note-take in detail, shape the conversation in a way
              that feels fluid and like a real discussion, and end with what
              feels like almost simultaneous synthesis afterward, already
              shaping up how she'll tweak the discussion next time and
              responding fluidly to any deviations or side discussions. She has
              a script but doesn't stick rigidly to it and she responds
              empathetically to participants. All of this means her work is
              responsive and adaptable at a split seconds notice.
            </p>
            <p>
              Whenever the team has asked her for something she's also been
              incredibly speedy and detailed in her response. Robyn was
              frequently called upon to effectively lead the team, making
              decisions or running activities normally beyond the scope of her
              role. In every instance, she met the challenge to help the team
              out, while gracefully and confidently highlighting her
              expectations of how the role should work together. In this way,
              she helped others understand their roles better too.
            </p>
            <p className={s.TestimonialFinisher}>
              TLDR - Working with Robyn has been one of the best professional
              experiences I've had and I hope our paths cross again one day!"
            </p>
          </div>

          <div className={s.Attribution}>
            <div
              className={s.Photo}
              style={{ backgroundImage: `url(${image1})` }}
              role="img"
              aria-label={
                "Katharine is looking at the camera and smiling. She has brown shoulder-length hair with a fringe and she is wearing black-rimmed glasses with a black blouse. There are green trees and a winding pavement behind her."
              }
            ></div>
            <div className={s.Reviewer}>
              <p className={s.ReviewerName}>Katharine Beer</p>
              <p className={s.ReviewerJob}>Senior Interaction Designer</p>
              <p className={s.ReviewerCompany}>Department for Education</p>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

// @TODO: refactor this post-MVP.
