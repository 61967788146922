// GENERAL IMPORTS
import s from "../App.module.scss";

// COMPONENT IMPORTS
import { Container } from "../components/Container/Container";
import { GalleryImage } from "../components/GalleryImage/GalleryImage";
import { ImageGallery } from "../components/ImageGallery/ImageGallery";
import { Section } from "../components/Section/Section";

// PHOTO IMPORTS
import Gardening from "../assets/images/about/garden.jpeg";
import Running from "../assets/images/about/running.jpeg";
import DIY from "../assets/images/about/diy.jpeg";
import Painting from "../assets/images/about/paint.jpeg";
import Travel from "../assets/images/about/travel.jpeg";
import Volunteer from "../assets/images/about/volunteer.jpeg";
import { useEffect } from "react";

export const AboutMe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <h2 className={s.AboutPageTitle}>
        I like to work hard and play hard. Here's what I get up to when no one
        else is looking! &#128513;
      </h2>
      <Section>
        <ImageGallery>
          <GalleryImage
            src={Gardening}
            tallImage
            activity="Gardening"
            tagTopLeft
          />
          <GalleryImage src={Painting} activity="Crafting" tagBottomRight />
          <GalleryImage
            src={Volunteer}
            activity="Volunteering"
            tagBottomRight
          />
          <GalleryImage src={DIY} activity="DIY" tagTopLeft />
          <GalleryImage
            src={Running}
            tallImage
            activity="Running + Hiking"
            tagBottomRight
          />
          <GalleryImage src={Travel} activity="Travelling" tagTopLeft />
        </ImageGallery>
      </Section>
    </Container>
  );
};
