// GENERAL IMPORTS
import s from "./Approach.module.scss";

// COMPONENT IMPORTS
import { Grid } from "../../components/Grid/Grid";

interface Props {
  children: React.ReactNode;
}

export const Approach = ({ children }: Props) => {
  return (
    <>
      <h2 className={s.Title}>My Approach</h2>
      <Grid twoThirds>
        <div></div>
        <div className={s.Spacer}>{children}</div>
        <div></div>
      </Grid>
    </>
  );
};

// @TODO: need to make this properly reusable down the line
