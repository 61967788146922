import React from "react";
import s from "./GalleryImage.module.scss";
import cn from "classnames";

interface Props {
  src: string;
  tallImage?: boolean;
  wideImage?: boolean;
  activity: string;
  tagTopLeft?: boolean;
  tagTopRight?: boolean;
  tagBottomRight?: boolean;
  tagBottomLeft?: boolean;
}

export const GalleryImage = ({
  src,
  activity,
  tallImage,
  wideImage,
  tagTopLeft,
  tagTopRight,
  tagBottomLeft,
  tagBottomRight,
}: Props) => {
  return (
    <div
      className={cn(s.GalleryImage, {
        [s.TallImage]: tallImage,
        [s.WideImage]: wideImage,
      })}
      style={{ backgroundImage: `url(${src})` }}
    >
      <div
        className={cn(s.Tag, {
          [s.Tag__topleft]: tagTopLeft,
          [s.Tag__topright]: tagTopRight,
          [s.Tag__bottomleft]: tagBottomLeft,
          [s.Tag__bottomright]: tagBottomRight,
        })}
      >
        {activity}
      </div>
    </div>
  );
};
