// GENERAL IMPORTS
import s from "./Journey.module.scss";

// COMPONENT IMPORTS
import { Container } from "../../components/Container/Container";
import { Grid } from "../../components/Grid/Grid";
import { JourneyStep } from "../../components/JourneyStep/JourneyStep";

// IMAGE IMPORTS
import Catch from "../../assets/images/experience/catch.png";
import PixeledEggs from "../../assets/images/experience/pixeled-eggs.jpeg";
import Shelter from "../../assets/images/experience/shelter.jpeg";
import RobSquared from "../../assets/images/experience/lot.jpeg";
import UWE from "../../assets/images/experience/uwe.jpeg";
import Nomensa from "../../assets/images/experience/nomensa.jpeg";

export const Journey = () => {
  return (
    <div className={s.ExperienceWrapper}>
      <Container>
        <section>
          <article>
            <h2 className={s.Title}>My Journey in to UX</h2>
            <Grid twoThirds>
              <div></div>
              <div className={s.Spacer}>
                <JourneyStep
                  title={"Apprentice Web Developer"}
                  company={"Catch"}
                  startYear={2015}
                  endYear={"2016"}
                  description={
                    "My first tech job was working as an apprentice web developer at an award-winning digital agency in Oxford Circus - Catch Digital! I learned how to code on-the-job, building websites for big-name brands like Rimmel, Thomas Cook Airlines, and the London Zoo. I benefited hugely from the experience of the team around me and working in collaboration with super passionate and talented devs (and I didn't even break any of our websites!...at least not in any ways they couldn't undo...)"
                  }
                  imgSrc={Catch}
                  imgAlt="Robyn sitting at a pub table with a bunch of her workmates from Catch. Everyone is smiling and a few people are holding their pint glasses up saying 'cheers'"
                ></JourneyStep>
                <JourneyStep
                  title={"Junior Web Developer"}
                  company={"Pixeled Eggs"}
                  startYear={2016}
                  endYear={"2017"}
                  description={
                    " My next dev job was at a smaller agency called Pixeled Eggs in Highbury and Islington. Although small they are fierce and I got to work on some really beautifully designed websites for big-name brands like Bedhead by Tigi, the Runaway Helpline, and Macallan Whiskey. Even more importantly, I got to hang out with the world's cutest office dog, Lola."
                  }
                  imgSrc={PixeledEggs}
                  imgAlt="Robyn is sitting on the grey office carpet with the office dog, Lola, sitting in her lap. Lola looks a bit nervous while trying to find her footing and Robyn is smiling down at her."
                ></JourneyStep>
                <JourneyStep
                  title={"Midweight Web Developer"}
                  company={"Shelter"}
                  startYear={2017}
                  endYear={"2018"}
                  description={
                    "My job at Shelter was the first time I got to experience the joy of my career aligning fully with my values. During my time at Shelter, I had the massive privilege of being a part of the Campaigns and Policy Team, responsible for rolling out crucial housing initiatives across the UK. As a part of this, I built rapid prototypes and microsites for ongoing campaigns and took an active role in Shelter's digital transformation programme by helping the team test, select, and implement new technologies and interviewing and hiring staff. I was very fortunate to be a part of Shelter's “Big Conversation”, “Yes DSS”, and “Longer Tenancies” campaigns, all of which were very successful in protecting the rights of vulnerable people across the UK (all of this whilst working with some of the loveliest people you will ever meet!)"
                  }
                  imgSrc={Shelter}
                  imgAlt="Robyn standing outside of the Shelter UK head office in London. She is mid-laugh while a bright red sign behind her reads 'run 15 miles to help fight homelessness'"
                ></JourneyStep>
                <JourneyStep
                  title={"Freelance Web Developer"}
                  company={"Rob Squared"}
                  startYear={2018}
                  endYear={"2020"}
                  description={
                    "Ready for more autonomy, my ex-husband and I started freelancing together. We worked on a range of projects from pure development work (me on the front-end, him on the back-end) to end-to-end user research, visual design, and CMS customisation. My proudest project was building the front-end for a self-service kiosk for the social enterprise Library of Things, which we integrated with a system of lockers to allow customers to borrow and return items without help from staff."
                  }
                  imgSrc={RobSquared}
                  imgAlt="Rob (Robyn's ex husband) and Sophia (Library of Things founder) are standing side-by-side at the counter looking down at a self-service kiosk together. Rob is walking her through some new functionality. You can see a range of tools on the wall behind them available to be borrowed and laptops and ipads are scattered on the counter."
                ></JourneyStep>

                <JourneyStep
                  title={"Creative Product Design Student"}
                  company={"University of the West of England"}
                  startYear={2020}
                  endYear={"2021"}
                  description={
                    "Finally getting UK citizenship and qualifying for home tuition fees, I went back to uni to pursue my dream of transitioning into UX. I studied Creative Product Design for one year before being told I was already ready for industry. During my first year, I scored some of the highest marks ever given to a first-year student. My proudest project included designing a hydraulic garlic press that could be used by people with arthritis to strengthen their hands and cook comfortably during flare-ups."
                  }
                  imgSrc={UWE}
                  imgAlt="A pavement leading up to the University of the West of England product design building. The building looks modern with crisp white paint, solar panels, and bright yellow poles showing."
                ></JourneyStep>
                <JourneyStep
                  title={"UX Consultant"}
                  company={"Nomensa"}
                  startYear={2021}
                  endYear={"present"}
                  description={
                    "I applied to Nomensa every year for 5 years before I got the job, and now with my newfound confidence under my belt I finally got it! I have been trusted with some of Nomensa's biggest accounts, including Virgin Media, Dyson, Sage, the Department for Education, and Deliveroo. I've benefited hugely from the wide range of clients that I have been trusted with and my super-talented team around me."
                  }
                  imgSrc={Nomensa}
                  imgAlt="The team is gathered around a bank of desks in the Nomensa office. You can see lots of iMacs, pens, and papers on the desks. Most people are standing with their arms crossed or hands in their pockets while many people are laughing"
                ></JourneyStep>
              </div>
              <div></div>
            </Grid>
          </article>
          <div className={s.Skills}>
            <Grid three>
              <div>
                <h3 className={s.SkillTitle}>Research Skills</h3>
                <p className={s.SkillBody}>
                  User interviews, stakeholder interviews, usability testing,
                  expert reviews, desk research, competitor analysis, persona
                  development, journey mapping, task analysis, user stories, etc
                </p>
              </div>
              <div>
                <h3 className={s.SkillTitle}>Soft Skills</h3>
                <p className={s.SkillBody}>
                  Critical thinking, problem-solving, active listening, fast
                  learning, leadership, confidence, stakeholder management,
                  flexibility, adaptability, creativity, curiosity
                </p>
              </div>
              <div>
                <h3 className={s.SkillTitle}>Tools & Languages</h3>
                <p className={s.SkillBody}>
                  Figma, Miro, Lucid, HTML, CSS / SCSS, Git
                </p>
              </div>
            </Grid>
          </div>
        </section>
      </Container>
    </div>
  );
};

// @TODO: need to make this properly reusable down the line
