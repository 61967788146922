import React from "react";
import s from "./Footer.module.scss";
import { Container } from "../../components/Container/Container";

export const Footer = () => {
  return (
    <footer>
      <div className={s.Footer}>
        <Container>&copy; Robyn Schlotfeldt</Container>
      </div>
    </footer>
  );
};
