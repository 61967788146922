// GENERAL IMPORTS
import s from "../App.module.scss";

// COMPONENT IMPORTS
import { FancyHeader } from "../sections/FancyHeader/FancyHeader";
import { Journey } from "../sections/Journey/Journey";
import { ProjectsListing } from "../sections/ProjectsListing/ProjectsListing";
import { Testimonials } from "../sections/Testimonials/Testimonials";
import { ClientReel } from "../sections/ClientReel/ClientReel";

// IMAGE IMPORTS
import FeaturedImage from "../assets/images/full-size-profile.jpeg";
import SecondaryImage from "../assets/images/postit-wall.jpeg";
import TertairyImage from "../assets/images/scattered-papers.jpeg";
import Katharine from "../assets/images/katharine.jpeg";
import { useEffect } from "react";

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <h1 className={s.hidden}>My Work | Robyn Schlotfeldt UX Portfolio</h1>
      <FancyHeader
        image1src={FeaturedImage}
        image2src={SecondaryImage}
        image3src={TertairyImage}
        image1alt="Robyn is looking straight at the camera, smiling. She has blonde hair, blue eyes, and is wearing a navy blue dress with colourful flowers on it."
        image2alt="A series of papers are taped up to the wall with a bunch of post it notes below them; this was a collaborative workshop activity."
        image3alt="Papers with rough ideation sketches are strewn on the floor - the result of an energetic and prouctive ideation workshop!"
        hiddenH2={"About Me"}
        title={
          "I'm Robyn. I'm a User Experience Consultant based in Bristol, England."
        }
        copy={
          "I am a user experience consultant with a background in front-end web development. I have experience working in a wide range of environments including digital agencies, in-house, and on a freelance basis both in the private and public sectors.  I particularly enjoy solving complex challenges and have a special interest in tech for good, but enjoy any problem to be solved!"
        }
      />
      <ProjectsListing title="My proudest projects" />
      <Journey />
      <Testimonials image1={Katharine} />
      <ClientReel />
    </>
  );
};

// @TODO: change this so emoji can be added in reusable way
// @TODO: need to refactor journey to be a proper re-usable component
// @TODO: resize images / compress - nobody needs to see your mug that big
// @TODO: change out favicon
// @TODO: make site title change on page level
// @TODO: need to add little blurby thing to show on search engines
