// GENERAL IMPORTS
import s from "./Result.module.scss";

interface Props {
  children: React.ReactNode;
}

export const Result = ({ children }: Props) => {
  return (
    <div className={s.Skills}>
      <div>
        <h3 className={s.ResultsTitle}>&#127881; The result &#127881;</h3>
        {children}
      </div>
    </div>
  );
};

// @TODO: need to make this properly reusable down the line
