import React from "react";
import cn from "classnames";
import s from "./Grid.module.scss";

interface PropsT {
  children: React.ReactNode;
  two?: boolean;
  three?: boolean;
  twoThirds?: boolean;
}

export const Grid = ({ children, two, three, twoThirds }: PropsT) => {
  return (
    <div
      className={cn(className, s.Grid, {
        [s.Grid_two]: two,
        [s.Grid_three]: three,
        [s.Grid_twoThirds]: twoThirds,
      })}
    >
      {children}
    </div>
  );
};
function className(
  className: any,
  arg1: { [x: string]: any }
): string | undefined {
  throw new Error("Function not implemented.");
}
