// GENERAL IMPORTS
import s from "./ProjectsListing.module.scss";

// COMPONENT IMPORTS
import { Container } from "../../components/Container/Container";
import { ProjectCard } from "../../components/ProjectCard/ProjectCard";
import { Grid } from "../../components/Grid/Grid";

// IMAGE IMPORTS
import DfeLogo from "../../assets/images/logos/blue/dfe.png";
import SageLogo from "../../assets/images/logos/blue/sage.png";
import EccLogo from "../../assets/images/logos/blue/ecc.png";
import LibraryOfThingsLogo from "../../assets/images/logos/blue/lot.png";
import VirginMediaLogo from "../../assets/images/logos/blue/vmi.png";
import DysonLogo from "../../assets/images/logos/blue/dyson.png";

interface PropsT {
  title?: string;
}

export const ProjectsListing = ({ title }: PropsT) => {
  return (
    <div className={s.SectionStyle}>
      <Container>
        <section>
          <h2 className={s.Title}>{title}</h2>
          <Grid two>
            {/* <ProjectCard
              client="Dyson"
              logo={DysonLogo}
              alt="Dyson logo"
              description="Give customers control over their communication preferences"
              url={"/dyson"}
              research
              wires
            /> */}
            <ProjectCard
              client="Department for Education"
              logo={DfeLogo}
              alt="Department for Education logo"
              description="Increase the number of apprentices who complete their apprenticeship"
              url="/department-for-education"
              research
            />
            <ProjectCard
              client="Sage"
              logo={SageLogo}
              alt="Sage logo"
              description="Empower customers to compare and select the best accounting products for their business"
              url="/sage"
              research
              wires
            />

            {/* <ProjectCard
              client="Virgin Media Ireland"
              logo={VirginMediaLogo}
              alt="Virgin Media Ireland logo"
              description="Enable customers to choose the right product, understand their bill, and troubleshoot independently"
              url="/virgin-media-ireland"
              wires
            /> */}
            {/* <ProjectCard
              client="Essex County Council"
              logo={EccLogo}
              alt="Essex County Council logo"
              description="Support the council and their constituents in moving to a new open-source content management system"
              url="/essex-county-council"
              research
            /> */}
            <ProjectCard
              client="The Library of Things"
              logo={LibraryOfThingsLogo}
              alt="Library of Things logo"
              description="Design a dashboard that allows the team to access and understand key business metrics at a glance"
              url={"the-library-of-things"}
              research
              wires
            />
          </Grid>
        </section>
      </Container>
    </div>
  );
};
