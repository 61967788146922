import { Container } from "../../components/Container/Container";
import { Section } from "../../components/Section/Section";
import s from "../../App.module.scss";
import { ApproachStep } from "../../sections/ApproachStep/ApproachStep";
import { Result } from "../../sections/Result/Result";
import { Approach } from "../../sections/Approach/Approach";

export const Dyson = () => {
  return (
    <>
      <Section>
        <Container>
          <p>Case Study: </p>
          <h1 className={s.PageTitle}>
            Give customers control over their communication preferences
          </h1>
        </Container>
      </Section>
      <Section className={s.Background}>
        <Container>
          <table className={s.Table}>
            {/* <thead>
              <tr>
                <th className={s.Head}>Label</th>
                <th className={s.Head}>Description</th>
              </tr>
            </thead> */}
            <tr className={s.Row}>
              <td className={s.Cell}>Client: </td>
              <td className={s.Cell}>Dyson </td>
            </tr>
            <tr className={s.Row}>
              <td className={s.Cell}>Problem: </td>
              <td className={s.Cell}>
                Dyson wants to encourage more customers to opt-in to receieve
                communications and also prevent existing subscribers from
                unsubscribing.
              </td>
            </tr>
            <tr className={s.Row}>
              <td className={s.Cell}>Project Phase</td>
              <td className={s.Cell}>Discovery</td>
            </tr>
            <tr className={s.Row}>
              <td className={s.Cell}>My Role</td>
              <td className={s.Cell}>User Research + Wireframing</td>
            </tr>
            <tr className={s.Row}>
              <td className={s.Cell}>Activities</td>
              <td className={s.Cell}>
                This is a work in progress, including research planning, journey
                mapping, expert review, best-practice research, competitor
                analysis, stakeholder mapping
              </td>
            </tr>
          </table>

          <Approach>
            <ApproachStep
              title={"Understood our goals, scope, and constraints"}
              activity={""}
              step={1}
              description={" "}
              imgSrc={""}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={
                "Learn about what options need to be included in the contact preference centre"
              }
              activity={""}
              step={2}
              description={""}
              imgSrc={""}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={
                "Understand the needs of future projects and infrastructure to ensure the solution can scale"
              }
              activity={""}
              step={2}
              description={""}
              imgSrc={""}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={
                "Understand what consents need to be collected in order to be compliant"
              }
              activity={""}
              step={2}
              description={""}
              imgSrc={""}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={
                "Research UX best practices and subscriber retention strategies"
              }
              activity={"User Story generation + prioritisation"}
              step={3}
              description={""}
              imgSrc={""}
              imgAlt={""}
            ></ApproachStep>
            <ApproachStep
              title={"Look at what competitors and comparators are doing"}
              activity={"User Story generation + prioritisation"}
              step={4}
              description={""}
              imgSrc={""}
              imgAlt={""}
            ></ApproachStep>
          </Approach>
          <Result>test</Result>
        </Container>
      </Section>
    </>
  );
};
