// FUNCTION IMPORTS
import React from "react";

// STYLE IMPORTS
import s from "./JourneyStep.module.scss";

interface Props {
  title: string;
  company: string;
  startYear: number;
  endYear: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
}

export const JourneyStep = ({
  title,
  company,
  startYear,
  endYear,
  description,
  imgSrc,
  imgAlt,
}: Props) => {
  return (
    <div className={s.Step}>
      <header>
        <h3 className={s.Title}>{title}</h3>
        <p className={s.Subtitle}>{company}</p>
      </header>
      <div className={s.MobileTag}>
        <div className={s.Years}>
          {startYear} - {endYear}
        </div>
      </div>
      <div
        className={s.MobilePhoto}
        style={{ backgroundImage: `url(${imgSrc})` }}
        role="img"
        aria-label={imgAlt}
      ></div>
      <div className={s.DesktopInterest}>
        <div className={s.TagWrapper}>
          <div className={s.Years}>
            {startYear} - {endYear}
          </div>
        </div>
        <div
          className={s.Photo}
          style={{ backgroundImage: `url(${imgSrc})` }}
          role="img"
          aria-label={imgAlt}
        ></div>
      </div>

      <p className={s.Summary}>{description}</p>
    </div>
  );
};
